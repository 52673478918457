<template>
  <div class="login-overlay">
    <div class="login-form">
      <span class="material-symbols-outlined close">close</span>       
      <div class="header">               
        <h3>Verificar credenciales</h3>
        <img src="../assets/img/eurekers.jpg" alt="logo" />        
      </div>
      <form>
        <div class="form-group">
          <label for="username">Usuario:</label>
          <input type="text" id="username" v-model="username" readonly />
        </div>
        <div class="form-group">
          <label for="usermail">Mail:</label>
          <input type="text" id="usermail" v-model="usermail" readonly />
        </div>
        <div class="form-group">
          <label for="password">Contraseña:</label>
          <input type="password" id="password" v-model="password" />
        </div>
        <button @click.prevent="submitForm">Ingresar</button>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
  mixins: [APICaller],
  name:'CursoOnlineLogin',
  data() {
    return {
      username: "",
      password: "",
      usermail: "",
    };
  },
  computed:{
    ...mapGetters(["_g_User"]),
  },
  mounted() {
    this.username = this._g_User.name;
    this.usermail = this._g_User.mail;
  },
  methods: {
    submitForm() {
      let success = (response) => {
        if (response.data == "PASSWORD ERROR") {
          alert('Error en la contraseña, por favor, confirma cual es la contraseña que utilizas para entrar en EUREKERS');
        }
        else {
          var login_response = JSON.parse(response.data);
          if (login_response.status == 200) {
            window.location.href = login_response.response.redirect_url + '?id=' + login_response.response.id + '&timestamp=' + login_response.response.timestamp + '&hash=' + login_response.response.hash;
          }
        }
      };

      let params = new URLSearchParams();
      params.append("username", this.username);
      params.append("password", this.password);

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "333",
        "No es posible el login. Contacte con nosotros"
      );
      var callOptions = {timeoutMs: 0};

      let url = "/api/v1/eurekers-courses-access";
      this._postAPICall(url, params, successHandler, failureHandler, callOptions);
    },
  },
};
</script>

<style scoped>
h3 {
  color: rgb(102, 102, 102);
}
.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.form-group {
  display: flex;
  align-items: center;
}

.form-group label {
  flex-basis: 30%;
}

.form-group input {
  flex-basis: 70%;
}

h2 {
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  margin-bottom: 5px;
  font-size: 12px;
}

input[type="text"],
input[type="password"] {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  color: rgb(84, 84, 84);
  border: 1px solid rgb(148, 148, 148);
}

input[type="text"]:focus,
input[type="password"]:focus {
  outline: none;
  border-color: rgb(53, 53, 53);
}

button {
  background-color: #00c3ff; /* Green */
  border: none;
  color: white;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 10px;
}

button:hover {
  background-color: #00a6ff; /* Dark Green */
}

.header {
  display: flex;
  align-items: center;
}

.header img {
  height: 50px;
  margin-left: 20px;
}

.close{
  float: right;
  top:0;
  cursor: pointer;
}
</style>
